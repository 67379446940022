module.exports = function ($) {
    var $carousel = $(this).find('.elementor-products-carousel');
    if (elementorFrontendConfig.isEditMode) {
        $(this).find('img[data-src]').each(function() {
            $(this).attr('src', $(this).data('src'));
        });
    }
    if (!$carousel.length) {
        return;
    }

    var respondTo = 'window';

    if (elementorFrontendConfig.isEditMode) {
        respondTo = 'iframe-window';
    }

    var savedOptions = $carousel.data('slider_options'),
        defaultOptions = {
            respondTo: respondTo,
            infinite: false,
            autoplaySpeed: 4500,
            rows: savedOptions.itemsPerColumn,
            rtl: parseInt(elementorFrontendConfig.is_rtl) ? true : false,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: savedOptions.slidesToShowTablet,
                        slidesToScroll: savedOptions.slidesToShowTablet
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: savedOptions.slidesToShowMobile,
                        slidesToScroll: savedOptions.slidesToShowMobile
                    }
                }
            ]
        },

        slickOptions = $.extend({}, defaultOptions, $carousel.data('slider_options'));

    $carousel.slick(slickOptions);

    if (elementorFrontendConfig.isEditMode) {
        $(window).on('changedDeviceMode', function () {
            $carousel.slick('getSlick').checkResponsive();
        });
    }
};
