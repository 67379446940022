module.exports = function ($) {
    var $this = $(this);
    var $contactFormWrapper = $this.find('.elementor-contactform-wrapper');

    if (!$contactFormWrapper.length) {
        return;
    }

    $contactFormWrapper.on("submit", ".js-lementor-contact-form", function (e) {
        e.preventDefault();
        $.post($(this).attr('action'), $(this).serialize(), null, 'json').then(function (resp) {
            $contactFormWrapper.find('.js-elementor-contact-norifcation-wrapper').replaceWith($(resp.preview).find('.js-elementor-contact-norifcation-wrapper'));
        }).fail(function (resp) {
            
        });
    });

};
